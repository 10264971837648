import generateRandomString from '../utillity/generateRandomString.js';

const dropdownAccordions = document.querySelectorAll('.accordion');

if (dropdownAccordions.length > 0) {
  const accordionArray = Array.from(dropdownAccordions);

  accordionArray.forEach((accordion) => {
    const accordionTriggerTitles = accordion.querySelectorAll('.accordionTitle');
    const accordionTriggerTitlesArray = Array.from(accordionTriggerTitles);

    accordionTriggerTitlesArray.forEach((accordionTriggerTitle) => {
      const accordionToggle = accordionTriggerTitle.querySelector('button');
      const accordionToggleText = accordionTriggerTitle.querySelector('span');
      const accordionPanel = accordionTriggerTitle.nextElementSibling;
      accordionPanel.style.opacity = 1;

      const accordionToggleIcon = accordionToggle.querySelector('svg');
      if (accordionToggleIcon) {
        accordionToggleIcon.setAttribute('aria-hidden', 'true');
      }

      // Generate random ID for each accordion's Title text & Panel and enhance the accessibility of the accordion progressively
      const accordionToggleTextID = `accordionToggleText-${generateRandomString(5, { includeUppercase: true })}`;
      accordionToggleText.setAttribute('id', accordionToggleTextID);

      const accordionPanelID = `accordionPanel-${generateRandomString(5, { includeUppercase: true })}`;
      accordionPanel.setAttribute('id', accordionPanelID);

      // Set the accordion toggle aria-controls attribute to the ID of the accordion panel and set the button to role button.
      accordionToggle.setAttribute('aria-controls', accordionPanelID);
      accordionToggle.setAttribute('aria-expanded', 'false');
      accordionToggle.setAttribute('type', 'button');

      // Set the accordion panel aria-labelledby attribute to the ID of the accordion toggle text and set the panel to role region.
      accordionPanel.setAttribute('aria-labelledby', accordionToggleTextID);
      accordionPanel.setAttribute('aria-hidden', 'true');
      accordionPanel.setAttribute('role', 'region');
      accordionPanel.classList.add('collapsed');

      accordionToggle.addEventListener('click', () => {
        // If the accordion is not expanded, expand it
        if (accordionToggle.getAttribute('aria-expanded') === 'false') {
          openPanel();
        } else {
          closePanel();
        }

        // function for opening the panel:
        function openPanel() {
          accordionToggle.setAttribute('aria-expanded', 'true');
          accordionPanel.setAttribute('aria-hidden', 'false');
          accordionPanel.classList.remove('collapsed');
          accordionPanel.classList.add('expanded');
        }

        // function for closing the panel:
        function closePanel() {
          accordionToggle.setAttribute('aria-expanded', 'false');
          accordionPanel.classList.add('animating');

          // Give the animation time to run before removing the expanded class:
          setTimeout(() => {
            accordionPanel.classList.remove('animating');
            accordionPanel.classList.remove('expanded');
            accordionPanel.classList.add('collapsed');
            accordionPanel.setAttribute('aria-hidden', 'true');
          }, 400); // 400ms is the duration of the animation + delay
        }
      });
    });

    // on window load set timeout to allow the accordion to animate in
    window.addEventListener('load', () => {
      accordion.classList.add('fade-in');

      setTimeout(() => {
        accordion.style.opacity = 1;
        accordion.classList.remove('fade-in');
      }, 400);

      // After accordion wrapper has faded in:
      // Stagger animate each accordionToggle by setting them to opacity 1 and add class fade in with a delay,
      // After each panel has animated in, remove fade-in class and set opacity to 1

      setTimeout(() => {
        accordionTriggerTitlesArray.forEach((accordionTriggerTitle) => {
          const accordionToggle = accordionTriggerTitle.querySelector('button');
          accordionToggle.style.opacity = 1;
          accordionToggle.classList.add('fade-in');
        });
      }, 400);
    });
  });
}
