// First order of buisness -> Remove no-js class from html element
document.documentElement.classList.remove("no-js");

// Global components
import ReferralHandler from "./global/ReferrerHandler.js";

new ReferralHandler();

import IframeFormDataHandler, {
  IFRAME_SELECTOR,
} from "./global/IframeFormDataHandler.js";
document
  .querySelectorAll(IFRAME_SELECTOR)
  .forEach((form) => new IframeFormDataHandler(form));

import stickyHeader from "./global/stickyHeader.js";
stickyHeader();

import headerLanguagePanel from "./global/headerLanguagePanel.js";
headerLanguagePanel();

import headerAlertPanel from "./global/headerAlertPanel.js";
headerAlertPanel();

import navigationAndSearch from "./global/navigationAndSearch.js";
navigationAndSearch();

import languageSelector from "./global/languageSelector.js";
languageSelector();

import ckEditorMediaEmbeder from "./global/ckEditorMediaEmbeder.js";
ckEditorMediaEmbeder();

// Blocks
import "./blocks/heroBlock.js";

import "./blocks/faqBlock.js";

import "./global/pageHero.js";

import "./blocks/imageTextBlock.js";

// vendor files
import "./vendor/iframeResize.js";
