export default function stickyHeader() {
  const headerContainer = document.querySelector(".headerContainer");

  let headerHeight = headerContainer.clientHeight;
  let scrollPos = window.scrollY;

  headerContainer.style.setProperty("--_headerTop", "0px");

  window.addEventListener("scroll", () => {
    headerHeight = headerContainer.clientHeight;

    const page = document.querySelector(".page");
    if (!page.classList.contains("mainNavigationActive")) {
      if (window.scrollY < scrollPos && window.scrollY > headerHeight) {
        headerContainer.style.setProperty("--_headerTop", "0px");
      } else if (window.scrollY > scrollPos && window.scrollY > 100) {
        headerContainer.style.setProperty("--_headerTop", `-${headerHeight}px`);
      }
    }
    scrollPos = window.scrollY;
  });

  const page = document.querySelector(".page");
  const className = "bodyScrolled";
  const headerScrollObserver = document.getElementById("bodyScrollObserver");

  var observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        page.classList.remove(className);
      } else {
        page.classList.add(className);
      }
    });
  });

  observer.observe(headerScrollObserver);
}
