import generateRandomString from '../utillity/generateRandomString.js';

const pageHero = document.getElementsByClassName('pageHero')[0];

if (pageHero) {

    /**
     * This is a MediaQueryList object representing the results of the specified media query string,
     * which checks whether the user has requested the system minimize the amount of animation or motion it uses.
     */
    const reducedMotionQuery = window.matchMedia('(prefers-reduced-motion: reduce)');

    /**
     * These are SVG path strings for the play and pause icons.
     */
    const playPath = 'M3 22v-20l18 10-18 10z';
    const pausePath = 'M11 22h-4v-20h4v20zm6-20h-4v20h4v-20z';

    /**
     * Creates a SVG element and returns it after setting its attributes.
     * @returns {SVGElement} SVG element
     */
    function createSvg() {
        let svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svg.setAttribute('aria-hidden', 'true');
        svg.setAttribute('fill', 'currentColor');
        svg.setAttribute('viewBox', '0 0 24 24');
        return svg;
    }

    /**
     * Creates a SVG path and returns it after appending it to the passed SVG element.
     * @param {SVGElement} svg - The SVG element to which the path is appended.
     * @returns {SVGPathElement} SVG path element
     */
    function createSvgPath(svg) {
        let path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        svg.appendChild(path);
        return path;
    }

    /**
     * Creates a video control button with SVG icon and returns them.
     * @returns {{button: HTMLButtonElement, path: SVGPathElement}} An object containing the created button and SVG path elements.
     */
    function createVideoControl() {
        let button = document.createElement('button');
        button.type = 'button';
        button.className = 'videoToggle';
        button.setAttribute('aria-label', 'Toggle video');

        let svg = createSvg();
        let path = createSvgPath(svg);

        button.appendChild(svg);
        return {button, path};
    }

    const videoElement = pageHero.querySelector('.pageHeroVideo');

    if (videoElement && videoElement.canPlayType('video/mp4')) {
        videoElement.removeAttribute('controls');

        const {button: videoControlButton, path: videoControlPath} = createVideoControl();

        const heroControls = document.createElement('div');
        heroControls.className = 'heroControls';
        heroControls.appendChild(videoControlButton);
        pageHero.prepend(heroControls);

        const videoID = `video-${generateRandomString(5, { includeUppercase: true })}`;
        videoElement.setAttribute('id', videoID);
        videoControlButton.setAttribute('aria-controls', videoID);

        const checkReducedMotion = () => {
            if (reducedMotionQuery.matches) {
                videoElement.pause();
                videoControlPath.setAttribute('d', playPath);
            } else {
                videoElement.play();
                setTimeout(() => {
                    if(videoElement.paused) {
                        videoControlPath.setAttribute('d', playPath);
                    } else {
                        videoControlPath.setAttribute('d', pausePath);
                    }
                }, 1000); // Check after 1 second
            }
        };

        const togglePlayPause = () => {
            if (videoElement.paused) {
                videoElement.play();
                videoControlPath.setAttribute('d', pausePath);
            } else {
                videoElement.pause();
                videoControlPath.setAttribute('d', playPath);
            }
        };

        reducedMotionQuery.addEventListener('change', checkReducedMotion);
        videoControlButton.addEventListener('click', togglePlayPause);
        checkReducedMotion();
    }
}
