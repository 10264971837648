export default function headerLanguagePanel() {
  const languagePanel = document.getElementById("languagePanel");
  if (!languagePanel) return;

  // Directly manipulating the panel based on sessionStorage without an explicit initialize call
  if (sessionStorage.getItem("languageDismissed") === "true") {
    languagePanel.setAttribute("data-expanded", "false");
  }

  // Setup the close event listener
  const languageToggleButton = document.getElementById("closeLanguagePanel");
  if (languageToggleButton) {
    languageToggleButton.addEventListener("click", function () {
      closeLanguagePanel(languagePanel);
    });
  }

  // confirm the selected language
  const languageConfirmationButton = document.getElementById("confirmLanguagePreference");
  if (languageConfirmationButton) {
      languageConfirmationButton.addEventListener("click", function () {
        confirmLanguagePreference();
      });
  }

  function closeLanguagePanel(languagePanel) {
    if (languagePanel.getAttribute("data-expanded") === "true") {
      languagePanel.classList.add("animating");
      setTimeout(function () {
        languagePanel.setAttribute("data-expanded", "false");
        sessionStorage.setItem("languageDismissed", "true");
        languagePanel.classList.remove("animating");
      }, 500);
    }
  }

  function confirmLanguagePreference() {
    // get selected language from language submenu
    const langSubMenu = document.getElementById("languageSubmenu-tD6ew");
    const selectedLanguage = langSubMenu.querySelectorAll("[data-selected] a")[0];
    window.location.href = selectedLanguage.href ?? "/";
  }
}
