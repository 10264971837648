import debounce from './debounce';
import { MOBILE_BREAKPOINT } from './../constants';

let isMobile = window.innerWidth <= MOBILE_BREAKPOINT;
/* console.log('isMobile', isMobile); */

function updateIsMobileView() {
    const wasMobile = isMobile;
    isMobile = window.innerWidth <= MOBILE_BREAKPOINT;
/*     console.log('isMobile', isMobile); */

    if (wasMobile !== isMobile) {
        // Emit a custom event when the viewport changes from mobile to desktop or vice versa.
        const event = new CustomEvent('viewportChange', {
            detail: {
                wasMobile: wasMobile,
                isMobile: isMobile
            }
        });
        window.dispatchEvent(event);
    }
}

window.addEventListener('resize', debounce(updateIsMobileView, 10));


export default function isMobileView() {
    return isMobile;
}