export default function languageSelector() {
    const languageSelectors = document.querySelectorAll('.languageSelector .languageToggle');

    // If no menu buttons were found, do nothing
    if (languageSelectors.length === 0) {
        return;
    }

    // For each languagSelector listen for clicks
    languageSelectors.forEach(languageSelector => {
        const parentElement = languageSelector.parentElement;

        languageSelector.addEventListener('click', () => {
            toggleLanguagePicker(languageSelector);
        });

        document.addEventListener('click', (event) => {
            if (languageSelector && !parentElement.contains(event.target)) {
                closeLanguagePicker(languageSelector);
            }
        });
    });

    const toggleLanguagePicker = (element) => {
        // Toggle the aria-expanded attribute on the button
        element.setAttribute('aria-expanded', element.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');

        // Toggle the class active on the next element wich is the language dropdown
        element.nextElementSibling.classList.toggle('active');
    }

    const closeLanguagePicker = (element) => {
        element.setAttribute('aria-expanded', 'false');
        element.nextElementSibling.classList.remove('active');
    }
}