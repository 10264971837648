import Cookies from "js-cookie";
import { REFERRER_COOKIE_NAME } from "./ReferrerHandler";
export const IFRAME_SELECTOR = "[data-form-salesforce]";

export default class IframeFormDataHandler {
  constructor(iframe) {
    this.iframe = iframe;
    this.initialSrc = this.iframe.getAttribute("src");
    this.init();
  }

  init() {
    window.addEventListener(
      "CookiebotOnAccept",
      this.onConsentGiven.bind(this),
      false
    );

    window.addEventListener(
      "CookiebotOnDecline",
      this.onConsentDeclined.bind(this),
      false
    );
  }

  updateIframeSrc() {
    const params = this.getReferrerParams();
    if (!params) return;

    this.iframe.src = this.initialSrc + params;
  }

  getReferrerParams() {
    const cookie = Cookies.get(REFERRER_COOKIE_NAME);

    if (!cookie) {
      return null;
    }

    return this.objectToQueryString(this.parseReferrerCookie(cookie));
  }

  parseReferrerCookie(cookie) {
    if (!cookie) return null;

    let obj = {};
    let pairs = cookie.split("|");

    pairs.forEach((pair) => {
      let [key, value] = pair.split("=");
      obj[key] = value;
    });

    return obj;
  }

  objectToQueryString(params) {
    if (!params) return null;

    return Object.keys(params)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
  }

  onConsentGiven() {
    this.updateIframeSrc();
  }

  onConsentDeclined() {
    this.iframe.src = this.initialSrc;
  }
}
