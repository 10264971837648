/**
 * ImageTextBlockAnimator Class
 * 
 * This class facilitates the animation of image and text blocks upon scrolling into view. 
 * When an image-text block enters the viewport, a scroll event listener is added to animate 
 * the '.blockMedia' child element within the block, moving it upwards as the user scrolls.
 * 
 */
class ImageTextBlockAnimator {

    /**
     * Constructor initializes the blocks to be animated, sets up an IntersectionObserver 
     * to monitor when these blocks come into view.
     *
     * @param {string} [selector='.imageTextBlock'] - CSS selector for the blocks to be animated.
     */
    constructor(selector = '.imageTextBlock') {
        this.blocks = document.querySelectorAll(selector);
        this.observer = new IntersectionObserver(
            this.handleIntersection.bind(this),
            { root: null, threshold: 0 }
        );
        this.init();
    }

    /**
     * Called when a block intersects with the viewport, attaching a scroll event 
     * listener for animation and stops observing the block.
     * 
     * @param {array} entries - IntersectionObserver entries.
     */
    handleIntersection(entries) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                window.addEventListener('scroll', this.handleScroll.bind(this, entry.target));
                this.observer.unobserve(entry.target);
            }
        });
    }

    /**
     * Handles the scroll event, computing the visibility ratio of the block, 
     * and updates the translateY value for the '.blockMedia' element.
     * 
     * @param {HTMLElement} block - The block element.
     */
    handleScroll(block) {
        const blockMedia = block.querySelector('.blockMedia');
        const rect = block.getBoundingClientRect();
        if (blockMedia && rect.top <= window.innerHeight && rect.top >= 0) {
            const visibilityRatio = 1 - (rect.top / window.innerHeight);
            const translateYValue = 150 - (150 * visibilityRatio);
            blockMedia.style.setProperty('--offset', `${translateYValue}px`);
            if (visibilityRatio >= 1) {
                block.classList.add('animated');
                window.removeEventListener('scroll', this.handleScroll);
            }
        }
    }

    /**
     * Initializes the observer for each block if there are any blocks 
     * and if the user hasn't set a preference for reduced motion.
     */
    init() {
        if (this.blocks.length > 0 && window.matchMedia("(prefers-reduced-motion: no-preference)").matches) {
            this.blocks.forEach(block => {
                const blockMedia = block.querySelector('.blockMedia');
                if (blockMedia) {
                    blockMedia.style.setProperty('--offset', '150px');
                    this.observer.observe(block);
                }
            });
        }
    }
}

// Usage
const animator = new ImageTextBlockAnimator();
