const MEDIA_SELECTOR = 'oembed[url]';

export default function ckEditorMediaEmbeder() {

    document.addEventListener('DOMContentLoaded', () => {
        const oembedElements = document.querySelectorAll(MEDIA_SELECTOR);

        oembedElements.forEach(element => {
            const url = element.getAttribute('url');

            // Youtube 
            if (isValidYouTubeUrl(url)) {
                embedYoutubeVideo(url, element);
            }
        });
    });
}

function embedYoutubeVideo(url, element) {
    const video_id = getYoutubeIdFromUrl(url);

    const iframe = document.createElement('iframe');
    iframe.setAttribute('width', '560');
    iframe.setAttribute('height', '315');
    iframe.setAttribute('src', `https://www.youtube.com/embed/${video_id}`);
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
    iframe.setAttribute('allowfullscreen', 'true');
    iframe.title = "YouTube video player";
    iframe.loading = "lazy";
    iframe.style.aspectRatio = "16/9";
    iframe.style.width = "100%";
    iframe.style.display = "block";
    iframe.style.height = "auto";
    iframe.style.margin = "0 auto";

    if (element.parentNode) {
        element.parentNode.replaceChild(iframe, element);
    }
}

function getYoutubeIdFromUrl(url) {
    let videoId = '';
    const regex = /[\\?&]v=([^\\?&]+)/;
    const matches = url.match(regex);
    if (matches) {
        videoId = matches[1];
    }

    if (!isValidYouTubeVideoID(videoId)) {
        throw new Error('Invalid YouTube video ID');
    }

    return videoId;
}

function isValidYouTubeVideoID(video_id) {
    const regex = /^[a-zA-Z0-9_-]{11}$/;
    return regex.test(video_id);
}

function isValidYouTubeUrl(url) {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    return youtubeRegex.test(url);
}
