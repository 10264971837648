export default function headerAlertPanel() {
  const alertPanel = document.getElementById("alertPanel");

  if (!alertPanel) {
    return;
  }

  if (sessionStorage.getItem("alertDismissed") === "true") {
    alertPanel.setAttribute("data-expanded", "false");
    return;
  }

  const alertToggleButton = document.getElementById("closeAlertPanel");

  alertToggleButton.addEventListener("click", function () {
    if (alertPanel.getAttribute("data-expanded") === "true") {
      alertPanel.classList.add("animating");

      setTimeout(function () {
        alertPanel.setAttribute("data-expanded", "false");
        sessionStorage.setItem("alertDismissed", "true");
        alertPanel.classList.remove("animating");
      }, 500);
    }
  });
}
